function initDataLayer(userId, clientIdKpaId, primaryServiceToIdKpaId,  ehsRole, hrRole, isImpersonated, clientName, locationName, platformRoleName, userName)
{
  dataLayer.push({
    'event' : 'pageview',
    'UserID': userId,
    'PrimaryServiceToIdKpaId': primaryServiceToIdKpaId,
    'ClientIdKpaId': clientIdKpaId,
    'EhsRole': ehsRole == undefined ? '' : ehsRole,
    'HrRole': hrRole == undefined ? '' : hrRole,
    'IsImpersonated': isImpersonated.charAt(0).toUpperCase() + isImpersonated.slice(1),
    'ClientName': clientName,
    'LocationName': locationName,
    'PlatformRoleName': platformRoleName,
    'UserName': userName
  });
}
