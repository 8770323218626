function initWalkMe(walkMeUrl, userId, clientId, vsRoleName, ehsRoleName, hrRoleName, hasAccessList)
{
  var walkme = document.createElement('script');
  walkme.type = 'text/javascript';
  walkme.async = true;
  walkme.src = walkMeUrl;

  var userVariables = new Object();

  userVariables.userId = userId;
  userVariables.clientId = clientId;
  userVariables.roleName = vsRoleName;
  userVariables.hasAccessList = hasAccessList;

  //if ehs and hr roles come in add them to variables.
  if (ehsRoleName) {
    userVariables.ehsRoleName = ehsRoleName;
  }

  if (hrRoleName) {
    userVariables.hrRoleName = hrRoleName;
  }

  window.addedWalkMeVariables = userVariables;

  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(walkme, s);
  window._walkmeConfig = { smartLoad: true };
}

